import React from 'react'
import ContentContainer from '../components/content-container/content-container'
import Layout from '../components/layout'
import ScalingBanner from '../components/scaling-banner/scaling-banner'
import Seo from '../components/seo'
import SmallSpacer from '../components/spacer/small-spacer'
import Form from '../components/contact/form'
import Locations from '../components/contact/locations'
import Map from '../components/contact/map'
import Spacer from '../components/spacer/spacer'
import FadeInView from '../components/fade-inview/fade-inview'
import { graphql } from 'gatsby'
import SubTitle from '../components/scaling-banner/sub-title'
import Enquiry from '../components/enquiry'
function Svg() {
    return (
        <svg width="1176" height="348" viewBox="0 0 1176 348" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path d="M153.681 141.002V76.3139C153.681 50.0343 133.466 29.8192 107.187 29.8192H86.9714C60.6918 29.8192 40.4767 50.0343 40.4767 76.3139V270.379C40.4767 298.68 58.6703 316.874 86.9714 316.874H107.187C135.488 316.874 153.681 298.68 153.681 270.379V201.648H111.23V270.379C111.23 273.209 109.612 274.422 107.187 274.422H86.9714C84.5456 274.422 82.9284 273.209 82.9284 270.379V76.3139C82.9284 73.4838 84.5456 72.2709 86.9714 72.2709H107.187C109.612 72.2709 111.23 73.4838 111.23 76.3139V141.002H153.681Z" fill="#EEEAD9" />
            <path d="M306.058 284.921C331.204 284.921 347.369 268.756 347.369 243.61V71.1832C347.369 47.8337 329.408 29.8726 306.058 29.8726H288.097C264.748 29.8726 246.786 47.8337 246.786 71.1832V243.61C246.786 268.756 262.952 284.921 288.097 284.921H306.058ZM288.097 247.202C285.942 247.202 284.505 246.125 284.505 243.61V71.1832C284.505 68.6687 285.942 67.591 288.097 67.591H306.058C308.214 67.591 309.65 68.6687 309.65 71.1832V243.61C309.65 246.125 308.214 247.202 306.058 247.202H288.097Z" fill="#EEEAD9" />
            <path d="M542.391 30.9387H508.384V154.012L480.855 30.9387H443.609V257.654H477.616V134.58L505.145 257.654H542.391V30.9387Z" fill="#EEEAD9" />
            <path d="M625.083 31.126V61.0963H655.054V230.928H685.024V61.0963H714.995V31.126H625.083Z" fill="#EEEAD9" />
            <path d="M786.328 205.118H812.607L816.111 178.089H842.889L846.393 205.118H872.672L847.645 29.929H811.355L786.328 205.118ZM829.375 73.7263L839.386 151.811H819.364L829.375 73.7263Z" fill="#EEEAD9" />
            <path d="M1017.28 90.1144V54.8082C1017.28 40.465 1006.25 29.4318 991.903 29.4318H980.87C966.526 29.4318 955.493 40.465 955.493 54.8082V160.727C955.493 176.173 965.423 186.103 980.87 186.103H991.903C1007.35 186.103 1017.28 176.173 1017.28 160.727V123.214H994.109V160.727C994.109 162.272 993.227 162.934 991.903 162.934H980.87C979.546 162.934 978.663 162.272 978.663 160.727V54.8082C978.663 53.2635 979.546 52.6015 980.87 52.6015H991.903C993.227 52.6015 994.109 53.2635 994.109 54.8082V90.1144H1017.28Z" fill="#EEEAD9" />
            <path d="M1097.41 29.5018V49.9228H1117.83V165.642H1138.25V49.9228H1158.67V29.5018H1097.41Z" fill="#EEEAD9" />
        </svg>

    )
}

export default function Contact({ data }) {

    return (
        <Layout>
            <Seo title='Contact' />
            <ScalingBanner video={data.contactPage.videoLink} image={data.contactPage.heroImage} svg={<Svg />} title='contact' >
                <div className="flex flex-end">
                    <SubTitle title='the smithfield team ' />
                </div>
            </ScalingBanner>
            <Spacer />
            <SmallSpacer className='m-hide' />
            <ContentContainer>
                <FadeInView>
                    <Form />
                </FadeInView>
                <Spacer />
                <div className='location--list enquiry'>
                    {data.contactPage.enquires.map((enquiry) => {
                        return (
                            <Enquiry name={enquiry.title} phone={enquiry.phone} manager={{ name: enquiry.managerName }} />
                        )
                    })}
                </div>

                <Spacer />

                <SmallSpacer />
                <FadeInView>
                    <Locations locations={data.datoCmsContact.locations} />
                </FadeInView>
                <Spacer />
                <FadeInView>
                    <Map />
                </FadeInView>
                <Spacer />
            </ContentContainer>
        </Layout>
    )
}


export const query = graphql`
query Contact {
  contactPage: datoCmsContactPage {
    enquires {
      phone
      title
      managerName
    }
    heroImage {
      url
    }
    videoLink
  }
  datoCmsContact {

    locations{
        bioSecurity{
            url
        }
        name
        manager{
            name  
        }
        phone
        address
        gmap
        receptionHours
        receptionPhone 
      
    }
  }
}

`