import React from 'react'

export default function Map() {
    return (
        <div className='r6 overflow-hidden re'>
          <div className='map--title'>
            <h5>2 locations.<br/>1 focus.</h5>

          </div>
          <iframe title='map' src="https://snazzymaps.com/embed/365508" width="100%" height="600px" style={{border:'none'}}></iframe>
        </div>
    )
}
