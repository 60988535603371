import React from 'react'
import Person from '../icons/person'
import SmallSpacer from './spacer/small-spacer'

export default function Enquiry({ name, manager, phone }) {

    return (
        <div className=''>
            <SmallSpacer />
            <div className='enquiry--item'>
                <h5 className='h5'>{name}</h5>


                <div className='flex ju-sb flex-c '>
                    <div>
                        <div className='flex al-i-c mb20'> <Person /> <h5 className='small-serif ml10'>manager</h5></div>
                        <p className='body-copy max-200'>{manager?.name}</p>
                    </div>
                    <SmallSpacer  />
                    <SmallSpacer  />
                    <a href={'tel:' + phone} className='body-copy '>{phone}</a>
                </div>

            </div>
            <SmallSpacer />
        </div>
    )
}