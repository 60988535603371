import React, { useEffect, useState } from 'react'
import SmallSpacer from '../spacer/small-spacer'
import { post } from 'axios'
import ArrowLink from '../arrow-link/arrow-link'
import gsap from 'gsap/all'




function useForm (required) {

    const [errored,setErrored] = useState({})
    const [submitted,setSubmitted] = useState(false)

    const  checkForm =  function checkForm (form,required) {
        return required.every((field) => form.get(field))
    }

    function getFields (form,required) {
       
        return required.filter(field => !form.get(field))
    }



    async function submit(event) {
        try {
            event.preventDefault()
            const form = event.target.closest('form')
            const data = new FormData(form)
          

            if(checkForm(data,required)){
                setErrored({})
                setSubmitted(true)
                await post('/', data)
              
               
            }else{
               const fieldsIssues = getFields(data,required)
               console.log(fieldsIssues)
               console.log()
               setErrored(fieldsIssues.reduce((fields,field) => ({...fields,[field]: true}),{}))
            }
        } catch (e) {
            console.error(e)
        }
    }

    return {submit,errored,submitted}
}



const required = [
    'name',
    'email',
    'Enquiry Type'
]




export default function Form() {


   const {submit,submitted,errored} = useForm(required)


   useEffect(() =>{
    if(submitted){
        gsap.to('.submit--hide',{opacity:0,duration:1,ease:'ease'})
        gsap.to('.contact--form--inner',{height:document.querySelector('.contact--form--inner').clientHeight})
        gsap.to('.submit--button',{height:document.querySelector('.submit--button').clientHeight})
        gsap.to('.submit--hide',{display:'none'},">")
        gsap.to('.submit-show',{display:'block'},'>')
        gsap.to('.submit-show',{opacity:1,ease:"ease",duration:1},'>')
    }
   },[submitted])

    return (
        <div>
            <h5>General Enquiries</h5>
            <SmallSpacer />
            <form  className='contact--form' name="contact" method="POST" data-netlify="true">
                <SmallSpacer />
                <input type="hidden" name="form-name" value="contact" />
                <div className='contact--form--inner'>
                    <div className='submit-show'>
                        <h3>thanks for your enquiry.</h3>
                    </div>
                    <div className={'contact--column submit--hide '}>
                        <h6 className='small-serif'>Name*</h6>
                        <input className={(errored['name'] ? 'error' :'')}  name='name' required placeholder='John Smith' />
                        <h6 className='small-serif'>Email Address*</h6>
                        <input className={(errored['email'] ? 'error' :'')} name='email' required placeholder='email@website.com' />
                        <h6 className='small-serif'>Enquiry Type*</h6>
                        <select className={(errored['type'] ? 'error' :'')} defaultValue='Please Select...' defaultChecked name='Enquiry Type' >
                            <option disabled selected value hidden='Please Select...'>Please Select...</option>
                            <option value="general">General</option>
                            <option value="general">Careers</option>
                            <option value="general">Business Enquiry</option> 
                        </select>
                        <h6 className='small-serif'>Subject</h6>
                        <input placeholder='Message...' name='subject' />
                    </div>
                    <div className={'contact--column submit--hide ' }>
                        <textarea placeholder='Message...' className='h100' name='message' />
                    </div>

                </div>
                <div className={'flex flex-end w100  submit--button ' } >
                    <div className='submit--hide'>
                    <button onClick={submit}>
                        <ArrowLink className='text-suede' name='submit' />
                    </button>
                    </div>
                </div>
            </form>
        </div>

    )
}
