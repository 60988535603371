import React from 'react'

export default function Reception() {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z" stroke="#ff6326 " stroke-linejoin="bevel" />
            <path d="M5.21324 7.50029L7.18589 9.07877L11.2129 5.00011" stroke="#ff6326 " />
        </svg>
    )
}
