import React from 'react'
import ArrowLink from '../arrow-link/arrow-link'
import SmallSpacer from '../spacer/small-spacer'
import Person from '../../icons/person'
import Location from '../../icons/location'
import Reception from '../../icons/reception'


export default function Locations({ locations }) {
    return (
        <div>
            <h5>our locations</h5>
            <SmallSpacer />
            <div className='location--list'>
                {locations.map((location) => {
                    return <LocationsList location={location} />
                })}
            </div>
        </div>
    )
}


function LocationsList({ location: { name, manager, address, phone, gmap, bioSecurity, receptionHours, receptionPhone } }) {

    return (
        <div className=''>
            <SmallSpacer />
            <div className='location--item'>
                <div className='flex flex-c ju-sb'>
                    <h6 className='small-serif'>{name}</h6>
                    <SmallSpacer />
                    <SmallSpacer />
                    {bioSecurity?.url ? <ArrowLink name='Biosecurity form' target='_blank' link={bioSecurity.url} /> : null}
                </div>

                <div className='m-hide' />


                <div className='flex ju-sb flex-c '>
                    <div>
                        <div className='flex al-i-c mb20'> <Location fill='white' /> <h5 className='small-serif ml10'>address</h5></div>
                        <p className='body-copy max-200'>{address}</p>
                    </div>
                    <SmallSpacer className='m-show' />
                    <button>
                        <ArrowLink link={gmap} className='text-suede' name='Get Directions' />
                    </button>
                </div>

                <div className='flex ju-sb flex-c '>
                    <div>
                        <div className='flex al-i-c mb20'> <Reception /> <h5 className='small-serif ml10'>Reception hrs</h5></div>
                        <p className='body-copy mb40' dangerouslySetInnerHTML={{ __html: receptionHours }}></p>
                        <a href={'tel:' + receptionPhone} className='body-copy '>{receptionPhone}</a>
                    </div>
                </div>

                <div className='flex ju-sb flex-c '>
                    <div>
                        <div className='flex al-i-c mb20'> <Person /> <h5 className='small-serif ml10'>manager</h5></div>
                        <p className='body-copy max-200'>{manager.name}</p>
                    </div>
                    <SmallSpacer className='m-show' />
                </div>

            </div>
            <SmallSpacer />
        </div>
    )
}