import React from 'react'

export default function Person() {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.57086 6.53992C8.10936 6.53992 9.35657 5.28829 9.35657 3.74432C9.35657 2.20036 8.10936 0.94873 6.57086 0.94873C5.03236 0.94873 3.78516 2.20036 3.78516 3.74432C3.78516 5.28829 5.03236 6.53992 6.57086 6.53992Z" stroke="#ff6326 " stroke-linejoin="bevel" />
            <path d="M1 13.9949C1 12.512 1.58699 11.0899 2.63184 10.0414C3.67669 8.99287 5.0938 8.40381 6.57144 8.40381C8.04908 8.40381 9.4662 8.99287 10.511 10.0414C11.5559 11.0899 12.1429 12.512 12.1429 13.9949" stroke="#ff6326 " stroke-linejoin="bevel" />
        </svg>

    )
}
